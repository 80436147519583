import React from 'react'

const PasswordRecovery: React.FC<unknown> = () => {
  return (
    <>
      <h2>Reset Password</h2>
      <p>Follow this link to reset the password for your user:</p>
      <p>
        <a href="{{ .SiteURL }}/admin/#recovery_token={{ .Token }}">
          Reset Password
        </a>
      </p>
    </>
  )
}

export default PasswordRecovery
